

import { defineComponent } from 'vue';
import store from "@/store";
import { Card, Table, DataList, Searchbar, IconButton } from '@/ui/index';
import { SessionController, EmployeeController, PresenceController, TimeEntryController, WorkbreakController } from '@/controller/';
import { User, Company, Employee, Workbreak, Presence } from '@/model';
import { DateTimeUtils } from '@/utils';

export default defineComponent({
    name: "Dashboard", 
    components: { Card, Table, DataList, Searchbar, IconButton },
    data(){
        return{
            store,
            sessionUser: {} as User,
            sessionCompany: {} as Company,
            sessionEmployee: {} as Employee,
            employees: [] as Array<any>,
            presences: [] as Array<any>,
            workbreaks: [] as Array<any>,
            searchInput: "",
            employeeTableData: {
                thFields: [
                    { title: this.$t("general.numberShort"), property: "number", type: "string", clickAction: "openDetail" },
                    { title: this.$t("employee.firstname"), property:"firstName", type: "string",  clickAction: "openDetail" }, 
                    { title: this.$t("employee.lastname"), property: "lastName", type: "string", clickAction: "openDetail" }, 
                    { title: this.$t("day.comeAt"), property: "presence.come", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.goAt"), property: "presence.go", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.workbreaks"), property: "workbreakSum", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.worktimesum"), property: "daySum", type: "string", convert: "convertSecondsToTime" }
                ],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }]
            },
            employeeStatisticData: {
                thFields: [
                    { title: this.$t("general.numberShort"), property: "number", type: "string", clickAction: "openDetail" },
                    { title: this.$t("employee.firstname"), property:"firstName", type: "string",  clickAction: "openDetail" }, 
                    { title: this.$t("employee.lastname"), property: "lastName", type: "string", clickAction: "openDetail" }, 
                    { title: this.$t("day.comeAt"), property: "come", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.goAt"), property: "go", type: "string", convert: "convertTimeStampToTime" },
                    { title: this.$t("day.presenceTime"), property: "presence", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.presencesAmount"), property: "countPresences", type: "number" },
                    { title: this.$t("day.workbreaks"), property: "sumWorkbreaks", type: "string", convert: "convertSecondsToTime" },
                    { title: this.$t("label.workbreaksAmount"), property: "countWorkbreaks", type: "number" },
                    { title: this.$t("label.nettoWorktime"), property: "workTime", type: "string", convert: "convertSecondsToTime" }
                ],
                tbActions: [{ name: "openDetail", icon: store.getters.svgIcons.watch }]
            },            
            gotEmployees: false,
            gotPresences: false,
            gotTimeEntries: false,
            gotWorkbreaks: false,
            activeDay: 0,
            employeeStatistics: [] as Array<any>,
            dataLoading: false
        }
    },
    computed: {
        displayActiveDay() : string {
            return DateTimeUtils.convertTimeStampToDate(this.activeDay, true)
        },            
        filteredEmployees() {
            let filtered = [] as Array<any>;
            if (this.searchInput) {
                this.employees.forEach((employee : any) => {
                    JSON.stringify(employee.firstName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                    !filtered.includes(employee) && JSON.stringify(employee.lastName).toLowerCase().includes(this.searchInput.toLowerCase()) && filtered.push(employee);
                })
            } else {
                filtered = this.employees;
            }
            console.log('filtered', filtered)
            return filtered;
        }        
    },
    created() {
        this.getSessionUser()
        this.getSessionCompany()
        this.getData()
        this.activeDay = DateTimeUtils.getDayNoonTimestamp(new Date())
    },
    methods:{
            //data
        getSessionUser() {
            this.sessionUser = SessionController.sessionUser
            this.sessionEmployee = SessionController.sessionEmployee
        },
        getSessionCompany() {
            this.sessionCompany = SessionController.sessionCompany;
        },
        async getData() {
            this.dataLoading = true
            await this.getEmployees()
            await this.getPresences()
            await this.getWorkbreaks()
            this.setEmployeeStatistics()
            this.dataLoading = false
        },
        async getEmployees() {
            const res = await EmployeeController.fetchEmployees();
            if(!res.error){
                this.employees = res.items;
                this.gotEmployees = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async getPresences() {
            //console.log('new Date', new Date(this.activeDay*1000))
            const day = DateTimeUtils.getMidnightToMidnight(new Date(this.activeDay*1000) /* new Date() */)
            console.log('day.start, day.end', day.start, day.end)
            const res = await PresenceController.getPresences(day.start, day.end, 0);
            if(!res.error){
                this.presences = res.items;
                this.gotPresences = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }            
        },
        async getWorkbreaks() {
            const day = DateTimeUtils.getMidnightToMidnight(new Date(this.activeDay*1000) /* new Date() */)
            const res = await WorkbreakController.getWorkbreaks(day.start, day.end, 0);
            if(!res.error){
                this.workbreaks = res.items;
                this.gotWorkbreaks = true
                this.checkDataLoaded()
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }            
        },        
        async getTimeEntries(employee: Employee) {
            const timeentries = await TimeEntryController.fetchDayTimeEntries(employee.uid, this.activeDay)
            if (timeentries?.items) {
                employee.setTimeEntries(timeentries.items)
                //employee.setRuntimeValues()
            }
        },

            //handlers
        handleSwitchDay(direction: any) {
            this.activeDay += 86400*direction
            const midnights = DateTimeUtils.getMidnightToMidnight(new Date())
            if (this.activeDay > midnights.end) {
                this.activeDay -= 86400;
            } else {
                this.getData()
            }
        },               
        handleTableActionClicked(action : string, item:any) {
            console.log("handleTable", action, item)
            switch(action) {
                case "openDetail":
                    this.$router.push('/administration/dashboard/employee/' + (item.employee ? item.employee : item.uid) + '/' + this.activeDay);
                    break
                case "confirmDelete":
                    //this.confirmDeleteEmployee(item);
                    break
                case "editEmployee":
                    //this.editEmployee(item.uid);
                    break
            }
        },      
        
            //function        
        checkDataLoaded() {
            //if (this.gotEmployees && this.gotPresences) {
            if (!this.gotTimeEntries && this.employees?.length) {
                this.employees.forEach((e: Employee) => {
                    this.getTimeEntries(e)
                })
                this.gotTimeEntries = true;
            }
            if (this.employees?.length && this.presences?.length) {
                this.presences.forEach(p => {
                    const employee = this.employees.find(e => e.uid == p.employee);
                    if (employee) employee.setPresence(p)
                })
            }
            if (this.employees?.length && this.workbreaks?.length) {
                this.workbreaks.forEach((wBreak: Workbreak) => {
                    const employee = this.employees.find((e: Employee) => e.uid == wBreak.employee)
                    if (employee) employee.addWorkbreak(wBreak)
                })
                this.employees.forEach((employee: Employee) => employee.setRuntimeValues())
            } 
            //}
        },
        setEmployeeStatistics() {
            this.employeeStatistics = [] as any[]
            //console.log('employeeStatistics', this.activeDay, this.presences)
            this.employees.forEach((employee: Employee) => {
                //console.log('employee', employee)
                    //[WS] create statistic values, do not take values from employee -> these are always the daily ones
                const presences = this.presences.filter((p: Presence) => (p.employee == employee.uid))

                let wbSum = 0
                const wbEntries = [] as number[]
                this.workbreaks.filter((wb: Workbreak) => wb.employee == employee.uid).forEach((wb: Workbreak) => {
                    wbEntries.push(wb.uid)
                    wbSum += (wb.stop ? wb.stop : +new Date()/1000) - wb.start
                })
                
                const entry = {
                    employee: employee.uid,
                    number: employee.number,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    come: presences.length ? presences[0].come : 0,
                    go: presences.length ? presences[0].go : 0,
                    presence: presences.length ? ((presences[0].go ? presences[0].go : +new Date()/1000) - presences[0].come) : 0,
                    presences: presences,
                    countPresences: presences.length,
                    presenceId: presences.length ? presences[0].uid : 0, 
                    sumWorkbreaks: wbSum,
                    workbreaks: wbEntries,
                    countWorkbreaks: wbEntries.length,
                    workTime: (presences.length ? ((presences[0].go ? presences[0].go : +new Date()/1000) - presences[0].come) : 0) - wbSum,
                    enableWorkbreakEdit: wbSum != 0
                }                
                if (entry.workTime < 0) entry.workTime = 0
                this.employeeStatistics.push(entry)
            })
        },        
    }
})
